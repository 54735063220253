import React from 'react';
import VideoScreen from 'common/components/VideoScreen';
import { ScalingPaddington } from 'common/components/Paddington';
import { Grid } from 'common/components/Grid';
import TextBlock from 'common/components/TextBlock';
import { useBackendStore } from 'services/backendService';

export default function Was() {
  const data = useBackendStore(state => state.was);
  if (!data) return <div>loading...</div>;
  const { headerVideo, textBlock } = data;
  const { video, thumbnail } = headerVideo;

  return (
    <>
      <VideoScreen video={video.url} poster={thumbnail.url} />
      <ScalingPaddington>
        <Grid>
          {textBlock.map((p, index) => (
            <TextBlock key={index} offsetText={index} headline={p.headline} copy={p.copy} />
          ))}
        </Grid>
      </ScalingPaddington>
    </>
  );
}
