import React, { useRef, useState } from 'react';
import { useBackendStore } from 'services/backendService';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import IconPlay from './icon_play.png';
import IconPlay2x from './icon_play@2x.png';
import IconPlaySvg from 'assets/icons/icon_play.svg';
import IconPause from './icon_pause.png';
import IconPause2x from './icon_pause@2x.png';
import defaultThumbnail from 'assets/thumbnail_default.png';
import { mediaMaxPx } from 'styles';

export const VideoContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.isPlaying ? colors.videoScreen.active : colors.videoScreen.base};
  transition: 0.5s;
  position: relative;
`;

const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  max-width: 100%;
`;

const PlayOverlay = styled.div`
  width: 100%;
  height: 100%;
  cursor: -webkit-image-set(url(${IconPlay}) 1x, url(${IconPlay2x}) 2x) 0 0, auto;

  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
`;

const PauseOverlay = styled(PlayOverlay)`
  width: 100%;
  height: 100%;
  cursor: -webkit-image-set(url(${IconPause}) 1x, url(${IconPause2x}) 2x) 4 12, auto;
`;

const VideoUiWrapper = styled.div`
  width: 100vw;
  height: fit-content;
  position: relative;
`;

const MobilePlayButton = styled.div`
  width: 36px;
  position: absolute;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  display: none;
  ${mediaMaxPx(
    700,
    css`
      display: ${props => (props.isPlaying ? 'none' : 'block')};
    `,
  )}
`;

export default function VideoScreen({ video, poster = defaultThumbnail }) {
  const env = useBackendStore(state => state.env);
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef();

  const toggleVideo = () => {
    playing ? videoRef.current.pause() : videoRef.current.play();
    setPlaying(!playing);
  };

  return (
    <VideoContainer isPlaying={playing}>
      <VideoUiWrapper>
        <StyledVideo
          ref={videoRef}
          src={env + video}
          autoPlay={false}
          loop={false}
          poster={env + poster}
          isPlaying={playing}
        />
        <MobilePlayButton isPlaying={playing} onClick={toggleVideo}>
          <IconPlaySvg />
        </MobilePlayButton>
        {playing ? <PauseOverlay onClick={toggleVideo} /> : <PlayOverlay onClick={toggleVideo} />}
      </VideoUiWrapper>
    </VideoContainer>
  );
}
