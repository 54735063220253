import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { mediaMaxPx } from 'styles';

export const FooterContainer = styled.div`
  height: fit-content;
  width: 100vw;
  padding: 32px 104px;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.menu};
  ${mediaMaxPx(
    700,
    css`
      flex-direction: column;
      padding: 40px;
    `,
  )}
`;

export const TextContainer = styled.div`
  padding: 0 16px;
  text-align: left;
  color: ${colors.text.veryLight};

  a {
    color: ${colors.text.veryLight};
    &:hover {
      opacity: 0.75;
    }
  }

  li {
    list-style: none;
  }

  ul {
    ${mediaMaxPx(
      700,
      css`
        padding: 0 8px;
      `,
    )};
  }

  ${mediaMaxPx(
    700,
    css`
      padding: 0 0 16px 0;
    `,
  )};
`;

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
`;

export const SocialIcon = styled.img`
  height: 25px;
  width: 25px;
  padding: 8px;
  filter: brightness(0) invert(1);
  &:hover {
    opacity: 0.75;
  }
`;
