import create from 'zustand';
import axios from 'axios';

const environments = {
  local: 'http://localhost:1337',
  dev: '',
  develop: 'https://ntmy-cms.herokuapp.com',
};
const pages = [
  { controller: '/home/clean', page: 'home' },
  { controller: '/wir/clean', page: 'wir' },
  { controller: '/was/clean', page: 'was' },
  { controller: '/referenzen/clean', page: 'referenzen' },
  { controller: '/sendung/clean', page: 'sendung' },
  { controller: '/kontakt/clean', page: 'kontakt' },
  { controller: '/menu/clean', page: 'menu' },
  { controller: '/blog-entries/clean', page: 'blogEntries' },
  { controller: '/angebot/clean', page: 'angebot' },
  { controller: '/footer/clean', page: 'footer' },
  { controller: '/impressum/clean', page: 'impressum' },
];

export const [useBackendStore, backendApi] = create((set, get) => ({
  env: environments.dev,
  home: null,
  wir: null,
  was: null,
  referenzen: null,
  sendung: null,
  kontakt: null,
  videos: null,
  currentEntry: null,
  blogEntries: null,
  angebot: null,
  footer: null,
  impressum: null,
  allowCookies: false,

  init: async () => {
    pages.map(page => get().getPageData(page.controller, page.page));
    if (localStorage.getItem('COOKIES')) get().setAllowCookies();
  },

  getPageData: async (controller, page) => {
    axios({ method: 'get', url: environments.develop + controller })
      .then(response => {
        set({ [page]: response.data });
      })
      .catch(error => {
        console.log(error);
      });
  },

  getBlogEntries: () => get().getPageData('/blog-entries/clean', 'blogEntries'),

  getSingleBlogEntry: id => {
    get().getPageData('/blog/clean/' + id, 'currentEntry');
  },

  setEnv: env => {
    set({ env: environments[env] });
  },

  setAllowCookies: () => {
    set({ allowCookies: true });
  },
}));
