import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import ReactMarkdown from 'react-markdown';
import ArrowRight from 'assets/icons/icon_arrow_right.svg';
import { backendApi, useBackendStore } from '../../services/backendService';
import { mediaMaxPx } from 'styles';

export const CookieJar = styled.div`
  width: 100vw;
  padding: 24px 40px;
  background-color: ${colors.menu};
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  color: ${colors.text.veryLight};
  display: ${props => (props.allowCookies ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-between;
  a {
    color: ${colors.text.veryLight};
  }
  p {
    margin: 0;
  }

  ${mediaMaxPx(
    700,
    css`
      flex-direction: column;
      align-items: flex-start;
    `,
  )}
`;

export const TextContainer = styled.div`
  margin: 0 40px 0 0;
  max-width: 600px;
  ${mediaMaxPx(
    700,
    css`
      margin: 0 0 24px 0;
    `,
  )}
`;

const AcceptContainer = styled.div`
  cursor: pointer;
  display: flex;
  font-weight: 700;

  svg {
    transition: 0.2s ease-in;
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    svg {
      transform: translateX(6px);
    }
  }
`;

const cookie = {
  copy:
    'Wir verwenden Cookies um die Performance und Funtionalität der Website zu gewährleisten. Weitere Informationen dazu finden Sie in unserer [Datenschutzerklärung](/impressum). ',
  accept: 'Zustimmen',
};
export default function CookieBanner() {
  const allowCookies = useBackendStore(state => state.allowCookies);
  const clickHandler = () => {
    localStorage.setItem('COOKIES', true);
    backendApi.getState().setAllowCookies();
  };

  return (
    <CookieJar allowCookies={allowCookies}>
      <TextContainer>
        <ReactMarkdown>{cookie.copy}</ReactMarkdown>
      </TextContainer>
      <AcceptContainer onClick={clickHandler}>
        {cookie.accept} <ArrowRight />
      </AcceptContainer>
    </CookieJar>
  );
}
