import styled from 'styled-components';

export const Paddington = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ScalingPaddington = styled(Paddington)`
  min-height: 100vh;
  height: fit-content;
`;
