import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { H1 } from 'styles/text';
import { mediaMaxPx } from 'styles';

export const BackgroundContainer = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 64px 64px 64px 104px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: none;
  word-break: break-word;

  a {
    text-decoration: none;
  }
  ${mediaMaxPx(
    700,
    css`
      padding: 64px 40px;
      justify-content: center;
      align-items: flex-start;
    `,
  )}
`;

export const StyledHeadline = styled(H1)`
  color: ${colors.secondary};
  margin: 16px 0;
  ${mediaMaxPx(
    940,
    css`
      font-size: 36px;
      line-height: 40px;
      margin: 8px 0;
    `,
  )}
`;

export const StyledMailLink = styled(StyledHeadline)`
  color: ${colors.secondary};
  cursor: pointer;
  position: relative;
  text-decoration: underline;
`;
