import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';
import { colors } from 'styles/colors';
import IconArrowLeft from 'assets/icons/icon_arrow_left.svg';
import IconArrowRight from 'assets/icons/icon_arrow_right.svg';

export const NavContainer = styled.div`
  width: 100%;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  ${props =>
    props.isAbsolute &&
    css`
      position: absolute;
      padding: 0 40px;
      bottom: 50px;
    `}

  ${mediaMaxPx(
    700,
    css`
      margin-top: 16px;
      justify-content: space-between;
    `,
  )};
`;

export const BulletContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Bullet = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 16px;
  border-radius: 50%;
  background-color: ${props => (props.active ? colors.primary : 'transparent')};
  &:hover {
    background-color: ${colors.primary};
  }
  border: 1px solid ${colors.primary};
  transition: 0.2s;
  cursor: pointer;
  ${mediaMaxPx(
    700,
    css`
      margin: 0 8px;
    `,
  )}
  ${mediaMaxPx(
    350,
    css`
      margin: 0 4px;
    `,
  )}
`;

export const ArrowLeft = styled(IconArrowLeft)`
  height: 16px;
  display: none;
  ${mediaMaxPx(
    700,
    css`
      display: block;
    `,
  )}
`;

export const ArrowRight = styled(IconArrowRight)`
  height: 16px;
  display: none;
  ${mediaMaxPx(
    700,
    css`
      display: block;
    `,
  )}
`;
