import React from 'react';
import { NavContainer, Bullet, BulletContainer, ArrowLeft, ArrowRight } from './styles';

export default function BulletNavigation({
  slides,
  activeSlide,
  moveLeft,
  moveRight,
  moveTo,
  absolute,
}) {
  return (
    <NavContainer isAbsolute={absolute}>
      <ArrowLeft onClick={moveRight} />
      <BulletContainer>
        {slides.map((slide, index) => (
          <Bullet key={'b' + index} onClick={() => moveTo(index)} active={index === activeSlide} />
        ))}
      </BulletContainer>
      <ArrowRight onClick={moveLeft} />
    </NavContainer>
  );
}
