import React from 'react';
import { BackgroundContainer, StyledHeadline, StyledMailLink } from './styles';
import { useBackendStore } from 'services/backendService';
import Loader from '../Loader';

export default function Kontakt() {
  const env = useBackendStore(state => state.env);
  const data = useBackendStore(state => state.kontakt);
  if (!data) return <Loader />;
  const { headline, telefon, emailHeadline, email, backgroundImage } = data;

  return (
    <BackgroundContainer image={backgroundImage && env + backgroundImage.url}>
      <StyledHeadline>{headline}</StyledHeadline>
      <StyledHeadline>{telefon}</StyledHeadline>
      <a href={`mailto:${email}`}>
        <StyledMailLink href={`mailto:${email}`}>{emailHeadline}</StyledMailLink>
      </a>
    </BackgroundContainer>
  );
}
