import React from 'react';
import styled from 'styled-components';

const BurgerContainer = styled.div`
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  pointer-events: all;
`;

const BurgerBun = styled.div`
  height: 6px;
  width: ${props => (props.isOpen ? 34 / props.factor : 34)}px;
  border-radius: 10px;
  background-color: ${props => props.navColor};
  transform-origin: left;
  transition: width 0.2s ease-in;
`;

const BurgerPatty = styled(BurgerBun)``;

export default function Burger({ clickHandler, navColor, isOpen }) {
  return (
    <BurgerContainer onClick={clickHandler}>
      <BurgerBun navColor={navColor} isOpen={isOpen} factor={1} />
      <BurgerPatty navColor={navColor} isOpen={isOpen} factor={2} />
      <BurgerBun navColor={navColor} isOpen={isOpen} factor={4} />
    </BurgerContainer>
  );
}
