import create from 'zustand';

export const [useMenuStore, menuApi] = create((set, get) => ({
  isOpen: false,
  navVisible: true,

  toggleIsOpen: () => {
    set({ isOpen: !get().isOpen });
  },
  toggleNavVisible: () => {
    set({ navVisible: !get().navVisible });
  },
}));
