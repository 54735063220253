import React from 'react';
import VideoScreen from 'common/components/VideoScreen';
import { ScalingPaddington } from 'common/components/Paddington';
import Person from './Person';
import styled from 'styled-components';
import { Grid } from 'common/components/Grid';
import { useBackendStore } from 'services/backendService';
import Loader from 'components/Loader';

export const PeopleContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  height: fit-content;
  display: flex;
  justify-content: center;
`;

export default function Wir() {
  const env = useBackendStore(state => state.env);
  const data = useBackendStore(state => state.wir);
  if (!data) return <Loader />;
  const { headerVideo, person } = data;
  const { video, thumbnail } = headerVideo;

  return (
    <>
      <VideoScreen video={video.url} poster={thumbnail.url} />
      <ScalingPaddington>
        <PeopleContainer>
          <Grid>
            {person.map((person, index) => (
              <Person
                key={index}
                image={env + person.personImage.url}
                name={person.name}
                copy={person.description}
              />
            ))}
          </Grid>
        </PeopleContainer>
      </ScalingPaddington>
    </>
  );
}
