import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 64px;
  display: flex;
  justify-content: center;
  ${mediaMaxPx(
    600,
    css`
      padding: 0;
    `,
  )}
`;
