export const baseColors = {
  white: '#FFF',
  black: '#1A1A1A',
  grey: { light: '#F2F2F7', dark: '#9AA1AF' },
  red: { base: '#F74A3C', dark: '#B11313' },
  yellow: '#FDD113',
  blue: '#2D94A9',
};

export const colors = {
  layout: { strong: baseColors.grey.dark, base: baseColors.grey.light },
  background: { strong: baseColors.white },
  text: {
    strong: baseColors.black,
    base: baseColors.grey.black,
    light: baseColors.grey.light,
    veryLight: baseColors.white,
    accent: baseColors.yellow,
    disabled: baseColors.red.dark,
  },
  underscore: baseColors.yellow,
  menu: baseColors.blue,
  videoScreen: { base: baseColors.white, active: baseColors.black },
  primary: baseColors.yellow,
  secondary: baseColors.red.base,
  nav: { dark: baseColors.black, light: baseColors.grey.light },
};

export const hexToRGB = (hex, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
};
