import React from 'react';
import { ScalingPaddington } from 'common/components/Paddington';
import { useBackendStore } from 'services/backendService';
import Loader from 'components/Loader';
import { H2 } from 'styles/text';
import { SingleColumnGrid } from 'common/components/Grid';
import TextBlock from 'common/components/TextBlock';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { mediaMaxPx } from 'styles';

export const BackgroundContainer = styled.div`
  text-align: left;
  padding: 64px 0;
  width: 100%;
  word-break: break-word;
  hyphens: manual;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 0;
    color: ${colors.text.accent};
    ${mediaMaxPx(
      700,
      css`
        margin-left: 0;
        font-size: 32px;
        line-height: 32px;
      `,
    )}
  }
`;

export default function Impressum() {
  const data = useBackendStore(state => state.impressum);
  if (!data) return <Loader />;
  const { impHeadline, impTextBlock, datenHeadline, datenTextBlock } = data;

  return (
    <ScalingPaddington>
      <BackgroundContainer lang="de">
        <SingleColumnGrid>
          <H2>{impHeadline}</H2>
          {impTextBlock.map((t, index) => (
            <TextBlock key={index} headline={t.headline} copy={t.copy} small />
          ))}
        </SingleColumnGrid>
        <SingleColumnGrid>
          <H2>{datenHeadline}</H2>
          {datenTextBlock.map((t, index) => (
            <TextBlock key={index} headline={t.headline} copy={t.copy} small />
          ))}
        </SingleColumnGrid>
      </BackgroundContainer>
    </ScalingPaddington>
  );
}
