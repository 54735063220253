import React from 'react';
import styled from 'styled-components';
import { menuApi } from 'services/menuService';
import { useHistory } from 'react-router-dom';
import { useMenuStore } from 'services/menuService';
import Burger from './Burger';
import LogoIcon from './assets/logo.svg';
import { useColorStore } from 'services/colorService';

const NavContainer = styled.div`
  width: 100%;
  height: 40px;
  position: fixed;
  top: 40px;
  left: 0;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
`;

const Logo = styled.div`
  width: 56px;
  height: fit-content;
  cursor: pointer;
  svg {
    path:first-child {
      transition: 0.2s ease-in;
      fill: ${props => props.navColor};
    }
  }
`;

export default function Nav() {
  const isVisible = useMenuStore(state => state.navVisible);
  const navColor = useColorStore(state => state.navColor);
  const history = useHistory();
  const isOpen = useMenuStore(state => state.isOpen);
  const toggleMenu = () => menuApi.getState().toggleIsOpen();
  const moveHome = () => {
    history.push('/');
    isOpen && toggleMenu();
  };
  return (
    isVisible && (
      <NavContainer>
        <Burger clickHandler={toggleMenu} navColor={navColor} isOpen={isOpen} />
        <Logo onClick={moveHome} navColor={navColor}>
          <LogoIcon />
        </Logo>
      </NavContainer>
    )
  );
}
