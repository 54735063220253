import React from 'react';
import { backendApi } from 'services/backendService';
export default function Config() {
  const setEnvironment = env => {
    backendApi.getState().setEnv(env);
  };
  return (
    <div>
      <button onClick={() => setEnvironment('local')}>Local</button>
      <button onClick={() => setEnvironment('dev')}>Dev</button>
    </div>
  );
}
