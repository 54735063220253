import React from 'react';
import { ScalingPaddington } from 'common/components/Paddington';
import { Grid } from 'common/components/Grid';
import Slideshow from 'common/components/Slideshow';
import TextBlock from 'common/components/TextBlock';
import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';
import { H2 } from 'styles/text';
import Loader from 'components/Loader';
import { useBackendStore } from 'services/backendService';

export const BrandContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const LogoContainer = styled.div`
  width: 20%;
  min-width: 50px;
  display: flex;
  justify-content: center;
  margin: 16px 0 16px 16px;
  cursor: pointer;
  img {
    max-height: 20px;
    max-width: 100%;
    width: auto;
    height: auto;
    filter: brightness(0) saturate(0) contrast(3);

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const SpacerHeadline = styled(H2)`
  margin: 0;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  width: 100%;
  padding-bottom: 36px;
  ${mediaMaxPx(
    700,
    css`
      display: none;
    `,
  )}
`;

export default function Referenzen() {
  const data = useBackendStore(state => state.referenzen);
  const env = useBackendStore(state => state.env);
  if (!data) return <Loader />;
  const { slideshow, textBlock, logoCollection } = data;
  const { slides } = slideshow;

  return (
    <>
      <Slideshow slides={slides} />
      <ScalingPaddington>
        <Grid>
          <TextBlock headline={textBlock.headline} copy={textBlock.copy} />
          <BrandContainer>
            <SpacerHeadline>{textBlock.headline}</SpacerHeadline>
            {logoCollection &&
              logoCollection.logo.map((item, index) => (
                <>
                  <LogoContainer key={'l' + index}>
                    <a href={item.link} rel="noreferrer" target={'_blank'}>
                      <img src={env + item.logoImage.url} />
                    </a>
                  </LogoContainer>
                </>
              ))}
          </BrandContainer>
        </Grid>
      </ScalingPaddington>
    </>
  );
}
