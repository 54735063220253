import React from 'react';
import { backendApi } from 'services/backendService';
import CookieBanner from '../CookieBanner';
import Menu from '../Menu';
import Nav from '../Nav';

export default function Start() {
  backendApi.getState().init();

  return (
    <>
      <Menu />
      <Nav />
      <CookieBanner />
    </>
  );
}
