import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Home from 'components/Home';
import Wir from 'components/Wir';
import Was from 'components/Was';
import Referenzen from 'components/Referenzen';
import Sendung from 'components/Sendung';
import Blog from 'components/Sendung/Blog';
import BlogEntry from 'components/Sendung/BlogEntry';
import Kontakt from 'components/Kontakt';
import Config from 'components/Config';
import Impressum from 'components/Impressum';
import Angebot from 'components/Angebot';

export default function Routes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const routes = [
    <Route key="home" path="/" component={Home} exact />,
    <Route key="wir" path="/wir" component={Wir} exact />,
    <Route key="was" path="/was" component={Was} exact />,
    <Route key="referenzen" path="/referenzen" component={Referenzen} exact />,
    <Route key="sendung" path="/textTonBild" component={Sendung} exact />,
    <Route key="blog" path="/blog" component={Blog} exact />,
    <Route key="blogEntry" path="/blog/:id/:headline" component={BlogEntry} exact />,
    <Route key="kontakt" path="/kontakt" component={Kontakt} />,
    <Route key="config" path="/config" component={Config} exact />,
    <Route key="impressum" path="/impressum" component={Impressum} exact />,
    <Route key="angebot" path="/angebot" component={Angebot} exact />,
  ];

  return (
    <>
      <Switch>
        {routes}
        <Redirect key={'redirect'} to={'/'} />
      </Switch>
    </>
  );
}
