import React from 'react';
import styled, { keyframes } from 'styled-components';
import { colors } from 'styles/colors';

export const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  font-weight: bold;
  font-size: 20px;
`;

const spin = keyframes`
0% {
  border-radius: 0px;
}
50% {
  border-radius: 50%;
   transform: rotate(360deg);
}
100% {
  border-radius: 50;
}`;

const Rect = styled.div`
  margin-left: 20px;
  width: 40px;
  height: 40px;
  background-color: ${colors.secondary};
  animation: ${spin} 4s infinite;
`;

export default function Loader() {
  return (
    <LoaderContainer>
      <Rect />
    </LoaderContainer>
  );
}
