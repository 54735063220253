import create from 'zustand';
import { colors } from 'styles/colors';

export const [useColorStore, colorApi] = create(set => ({
  navColor: colors.nav.dark,

  setNavColor: theme => {
    set({ navColor: colors.nav[theme] });
  },
}));
