import styled, { css, keyframes } from 'styled-components';
import { H3 } from 'styles/text';
import { colors } from 'styles/colors';
import { mediaMaxPx } from 'styles';
import { hexToRGB } from 'styles/colors';

export const Container = styled.div`
  padding: 64px;
  height: fit-content;
  ${mediaMaxPx(
    600,
    css`
      padding: 64px 0 0 0;
    `,
  )}
`;

const hoverBackground = keyframes`
  from{box-shadow: 7px 7px 0px 0px ${hexToRGB(colors.secondary, 0)};}
  to {box-shadow: 7px 7px 0px 0px ${hexToRGB(colors.secondary, 1)};}
`;

export const TeaserContainer = styled.div`
  width: 100%;
  padding-left: 64px;
  display: flex;
  align-items: flex-start;
  margin: 64px 0;
  cursor: pointer;

  img {
    max-width: 254px;
  }

  img:hover {
    animation: ${hoverBackground} 0.2s linear forwards;
  }

  ${mediaMaxPx(
    600,
    css`
      flex-direction: column;
      padding-left: 0;
      img {
        width: 100%;
      }
    `,
  )}
`;

export const TeaserTextContainer = styled.div`
  padding: 8px 24px;
  max-width: 500px;
  ${mediaMaxPx(
    600,
    css`
      padding: 16px 0 8px 0;
    `,
  )}
`;

export const TeaserHeadline = styled(H3)`
  color: ${colors.text.accent};
  margin: 0;
`;

export const TeaserCopy = styled.p`
  margin: 0;
`;
