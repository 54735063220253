import styled, { css } from 'styled-components';
import { mediaMaxPx } from 'styles';

export const Grid = styled.div`
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 45% 45%;
  column-gap: 10%;
  row-gap: 64px;
  margin-bottom: 40px;
  padding: 32px 64px;
  ${mediaMaxPx(
    700,
    css`
      grid-template-columns: 100%;
      padding: 0;
    `,
  )}
`;

export const SingleColumnGrid = styled(Grid)`
  grid-template-columns: 100%;
  max-width: 800px;
  row-gap: 32px;
`;
