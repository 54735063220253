import React, { useRef, useState, useEffect } from 'react';
import {
  HeaderContainer,
  StyledVideo,
  HeaderImage,
  StyledHeadline,
  VideoUi,
  PlayOverlay,
  PauseOverlay,
  IconContainer,
  MobilePlayButton,
} from './styles';
import { useBackendStore } from 'services/backendService';
import SpeakerMute from 'assets/icons/icon_speaker_mute.svg';
import Speaker from 'assets/icons/icon_speaker.svg';
import Fullscreen from 'assets/icons/icon_fullscreen.svg';
import IconPlaySvg from 'assets/icons/icon_play.svg';

export default function HeaderContent({ headline, content, type, poster }) {
  const env = useBackendStore(state => state.env);
  const videoRef = useRef();
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const playVideo = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const pauseVideo = () => {
    videoRef.current.pause();
    setIsPlaying(false);
  };

  const toggleFullscreen = () => {
    videoRef.current.requestFullscreen();
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  const toggleSound = () => {
    setIsMuted(!isMuted);
  };

  const toggleVideo = () => {
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  return (
    <HeaderContainer>
      {type === 'video/mp4' ? (
        <VideoUi>
          <StyledVideo
            poster={env + poster}
            ref={videoRef}
            onClick={toggleVideo}
            src={env + content.url}
            allowFullScreen
          />
          {isPlaying ? (
            <PauseOverlay onClick={toggleVideo} />
          ) : (
            <PlayOverlay onClick={toggleVideo} />
          )}
          <MobilePlayButton onClick={toggleVideo} isPlaying={isPlaying}>
            <IconPlaySvg />
          </MobilePlayButton>
          <IconContainer>
            {isMuted ? <SpeakerMute onClick={toggleSound} /> : <Speaker onClick={toggleSound} />}
            <Fullscreen onClick={toggleFullscreen} />
          </IconContainer>
        </VideoUi>
      ) : (
        <HeaderImage src={env + content.url} />
      )}
      <StyledHeadline>{headline}</StyledHeadline>
    </HeaderContainer>
  );
}
